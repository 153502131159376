'use client';
import { getHarRequest, getParametersFromOperation, getRequestFromOperation, } from '@scalar/oas-utils';
import React from 'react';
import { fromJSON } from './fetchOpenAPIOperation';
const ApiClientReact = React.lazy(async () => {
    const mod = await import('@scalar/api-client-react');
    return { default: mod.ApiClientReact };
});
const ScalarContext = React.createContext(() => { });
/**
 * Button which launches the Scalar API Client
 */
export function ScalarApiButton(props) {
    const { fetchOperationData } = props;
    const open = React.useContext(ScalarContext);
    return (React.createElement("div", { className: "scalar scalar-activate" },
        React.createElement("button", { className: "scalar-activate-button", onClick: () => {
                open(fetchOperationData);
            } },
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "10", height: "12", fill: "none" },
                React.createElement("path", { stroke: "currentColor", strokeWidth: "1.5", d: "M1 10.05V1.43c0-.2.2-.31.37-.22l7.26 4.08c.17.1.17.33.01.43l-7.26 4.54a.25.25 0 0 1-.38-.21Z" })),
            "Test it")));
}
/**
 * Wrap the rendering with a context to open the scalar modal.
 */
export function ScalarApiClient(props) {
    const { children } = props;
    const [active, setActive] = React.useState(null);
    const proxy = '/~scalar/proxy';
    const open = React.useCallback(async (fetchOperationData) => {
        setActive({ operationData: null });
        const operationData = fromJSON(await fetchOperationData());
        setActive({ operationData });
    }, []);
    const onClose = React.useCallback(() => {
        setActive(null);
    }, []);
    const request = React.useMemo(() => {
        const operationData = active?.operationData;
        if (!operationData) {
            return null;
        }
        const operationId = operationData.operation.operationId ?? operationData.method + operationData.path;
        const operation = {
            ...operationData,
            httpVerb: operationData.method,
            pathParameters: operationData.operation.parameters,
        };
        const variables = getParametersFromOperation(operation, 'path', false);
        const request = getHarRequest({
            url: operationData.path,
        }, getRequestFromOperation({
            ...operation,
            information: {
                requestBody: operationData.operation.requestBody,
            },
        }, { requiredOnly: false }));
        const data = {
            id: operationId,
            type: operationData.method,
            path: operationData.path,
            variables,
            cookies: request.cookies.map((cookie) => {
                return { ...cookie, enabled: true };
            }),
            query: request.queryString.map((queryString) => {
                const query = queryString;
                return { ...queryString, enabled: query.required ?? true };
            }),
            headers: request.headers.map((header) => {
                return { ...header, enabled: true };
            }),
            url: operationData.servers[0]?.url,
            body: request.postData?.text,
        };
        return data;
    }, [active]);
    return (React.createElement(ScalarContext.Provider, { value: open },
        children,
        active ? (React.createElement("div", { className: "scalar" },
            React.createElement("div", { className: "scalar-container" },
                React.createElement("div", { className: "scalar-app" },
                    React.createElement(React.Suspense, { fallback: React.createElement(ScalarLoading, null) },
                        React.createElement(ApiClientReact, { close: onClose, proxy: proxy, isOpen: true, request: request }))),
                React.createElement("div", { onClick: () => onClose(), className: "scalar-app-exit" })))) : null));
}
function ScalarLoading() {
    return React.createElement("div", { className: "scalar-app-loading" }, "Loading...");
}
